// Fontawesome
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss';

// Fonts
@import 'fonts.scss';

// Variables
$color_blue: #003659 !important;
$font_brown: #806553;

// General
body{
    font-family: Ubuntu, sans-serif;
    font-size: 20px;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    color: $font_brown;
    background: #f6f7f8;
    overflow-x: hidden !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.section-title{
    font-family: 'SegoePrint', serif;
    color: $font_brown;
    font-weight: 400;
    line-height: 44px;
    text-align: center;
    font-size: 26px;
    @media (min-width: 768px) {
        font-size: 28px;
    }
    @media (min-width: 1200px) {
        font-size: 32px;
    }
    @media (min-width: 1600px) {
        font-size: 34px;
    }
}
.section-title-underline{
    font-family: 'SegoePrint', serif;
    text-align: center;
    font-size: 18px;
}
.section-title-medium{
    font-family: 'SegoePrint', serif;
    color: $color_blue;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -1px;
}
h3{
    color: $color_blue;
    font-size: 21px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: normal;
}
p{
    font-size: 22px;
    line-height: 34px;
    font-weight: 400;
    margin-bottom: 20px;
}

a{
    color: $color_blue;
    transition: all 150ms ease-in-out;
    text-decoration: none;
    background-color: transparent;
    &:hover, &:focus{
        color: $color_blue;
        text-decoration: underline;
        text-decoration-style: dashed;
    }
    &:hover, &:active{
        outline: 0;
    }
}
b, strong{
    font-weight: 500;
    color: $color_blue;
}
.icon-list{
    list-style: none;
    padding: 0;
    li{
        margin-left: 25px;
        margin-bottom: 5px;
        line-height: 26px;
        padding-right: 1rem;
        i{
            margin-left: -25px;
            font-style: italic;
            padding-right: 5px;
            vertical-align: 0;
            font-size: 18px;
        }
    }
}
.list-blue{
    i{
        color: $color_blue;
    }
}
.gerneperdu{
    font-size: 0.8rem;
    color: gray;
}
::selection {
    background: #fefac7;
    color: #404040;
}

// Content
.content-wrapper{
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

// Content Left
.content-left {
    height: 650px;
    background-size: cover;
    background-position: center 0;
    background-color: transparent;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    @media (min-width: 1200px) {
        width: 40%;
        position: fixed;
        height: 100vh;
        background-position: center center;
    }
    @media (max-height: 600px) {
        height: 450px;
        background-position: center 10px;
    }
}

// Content Right
.content-right{
    padding: 0;
    color: rgb(77,60,50);
    @media (min-width: 1200px) {
        margin-left: 40%;
        width: 60%;
    }
}
.section-wrapper{
    &.light-wrapper{
        background: #f6f7f8;
    }
    &.schlamm-wrapper{
        background: #d7c7bb;
    }
    .inner{
        padding: 45px 15px;
        hyphens: auto;
        @media (min-width: 768px) {
            padding: 90px 7vw;
        }
        @media (min-width: 992px) {
            padding: 90px 100px;
        }
        @media (min-width: 1200px) {
            padding: 90px 3vw;
        }
        @media (min-width: 1800px) {
            padding: 90px 100px;
        }
        .logo{
            width: 400px;
            margin-bottom: 80px;
            max-width: 100%;
        }
        .section-title-box{
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
}
#talente, #qualifikationen, #kontakt, #vita{
    .section-title{
        margin-bottom: 25px;
        @media (min-width: 768px) {
            margin-bottom: 50px;
        }
    }
}

// Progress Graph
#talente .progress-box{
    margin: 0 auto;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    .progress-box-row{
        flex: 0 0 auto;
        max-width: 100%;
        margin: 0 auto 50px;
        padding-left: 20px;
        padding-right: 20px;
        width: 90%;
        @media (min-width: 400px) {
            width: 70%;
        }
        @media (min-width: 768px) {
            width: 33.3333%;
            margin: 0;
        }
        .progress-graph-box{
            margin: 0 5px 10px;
            @media (min-width: 400px) {
                margin: 0 35px 10px;
            }
            @media (min-width: 768px) {
                margin: 0 15px 20px;
            }
            @media (min-width: 1200px) {
                margin: 0 15px 20px;
            }
            @media (min-width: 2000px) {
                margin: 0 35px 20px;
            }
            .progressbar-text{
                font-size: 26px;
                @media (min-width: 400px) {
                    font-size: 26px;
                }
                @media (min-width: 768px) {
                    font-size: 20px;
                }
                @media (min-width: 991px) {
                    font-size: 30px;
                }
                @media (min-width: 1200px) {
                    font-size: 25px;
                }
                @media (min-width: 1600px) {
                    font-size: 38px;
                }
                @media (min-width: 1800px) {
                    font-size: 40px;
                }
            }
        }
        .progress-text-box{
            .progress-text-title{
                font-family: "SegoePrint", serif;
                text-align: center;
                color: $color_blue;
                font-size: 1rem;
                line-height: 1.2;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 1rem;
                @media (min-width: 768px) {
                    height: 40px;
                }
            }
            .progress-text-body{
                text-align: center;
                font-size: 1rem;
                line-height: 1.2;
            }
        }
    }
}

// Progress Line
#qualifikationen .progress-box{
    margin: 0 auto;
    .progress-box-row{
        width: 100%;
        flex: 0 0 auto;
        max-width: 100%;
        margin: 0 0 25px;
        padding: 0;
        .progress-graph-box{
            margin: 0;
            .progress-line{
                height: 12px;
                position: relative;
            }
            .progressbar-text{
                font-size: 1rem;
                font-family: "SegoePrint", serif;
            }
        }
        .progress-text-box{
            margin-bottom: -9px;
            .progress-text-title{
                font-family: "SegoePrint", serif;
                color: $color_blue;
                font-size: 1rem;
                line-height: 1.2;
                text-align: left;
                padding-right: 100px;
            }
            .progress-text-body{
                text-align: center;
                font-size: 1rem;
                line-height: 1.2;
            }
        }
    }
}

// Vita
#vita{
    .timeline{
        position: relative;
        padding: 0;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -1px;
            height: 100%;
            width: 1px;
            background: rgba(0, 0, 0, 0.1);
            @media (max-width: 767px){
                left: 40px;
            }
        }
        .timeline-block{
            &:first-child{
                margin-top: 0;
            }
            &:last-child{
                margin-bottom: 0;
            }
            &:before, &:after{
                content: " ";
                display: table;
            }
            &:after{
                clear: both;
            }
            position: relative;
            margin: -80px 0;
            @media (max-width: 767px){
                margin: 0;
                width: 100%;
                padding-left: 100px;
            }
            .timeline-icon{
                position: absolute;
                top: 0;
                left: 50%;
                margin-top: 5px;
                text-align: center;
                -webkit-backface-visibility: hidden;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                @media (max-width: 767px){
                    left: 40px;
                }
                &:before{
                    content: "";
                    position: absolute;
                    background: $color_blue;
                    width: 15px;
                    height: 15px;
                    max-width: 15px;
                    max-height: 15px;
                    margin-left: -8px;
                    margin-top: 28px;
                    border-radius: 100%;
                }
            }
            .timeline-content{
                position: relative;
                width: 45%;
                @media (max-width: 767px){
                    float: none;
                    width: 100%;
                }
                .meta{
                    font-family: "SegoePrint", serif;
                    color: $color_blue;
                    text-transform: uppercase;
                    font-size: 20px;
                    @media (min-width: 1200px) {
                        font-size: 18px;
                    }
                    @media (min-width: 1600px) {
                        font-size: 20px;
                    }
                }
                p, ul, li{
                    font-size: 18px;
                    @media (min-width: 1200px) {
                        font-size: 16px;
                    }
                    @media (min-width: 1600px) {
                        font-size: 18px;
                    }
                }
                p{
                    margin-bottom: 7px;
                    line-height: 26px;
                }
            }
            .box{
                border-radius: 4px;
                padding: 30px;
                margin-bottom: 20px;
                position: relative;
                -webkit-transition: all 150ms ease-in-out;
                -o-transition: all 150ms ease-in-out;
                transition: all 150ms ease-in-out;
            }
            .box-arrow{
                &.left{
                    float: right;
                }
                &.right:after{
                    right: -10px;
                    border-left: 10px solid #fff;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    @media (max-width: 767px){
                        left: -10px;
                        right: auto;
                        border-right: 10px solid #fff;
                        border-left: transparent;
                    }
                }
                &.left:after{
                    left: -10px;
                    right: auto;
                    border-right: 10px solid #fff;
                    border-left: transparent;
                }
                &:after{
                    content: "";
                    position: absolute;
                    top: 30px;
                    right: -10px;
                    border-left: 10px solid #fff;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                }
            }
            .box.bg-white{
                background: #FFF;
                -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
                filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));
            }
        }
    }
}

// Ueber mich
.table-kompetenz{
    td{
        vertical-align: top;
        p:last-child{
            margin-bottom: 40px;
        }
    }
    input{
        width: 90px!important;
        height: auto!important;
        padding-right: 15px;
    }
}

// Angebote
.section-courses{
    .isotop-filter{
        text-align: center;
        ul{
            position: relative;
            z-index: 2;
            font-size: 1rem;
            padding: 0;
            margin-bottom: 10px;
            li{
                display: inline-block;
                padding: 0;
                margin: 0 1rem;
                cursor: pointer;
            }
        }
    }
    .grid-item{
        width: 48%;
        margin: 0 2% 1% 0;
        figure.fyndery{
            position: relative;
            height: 100%;
            overflow: hidden;
            transition: all 0.4s ease-in-out;
            color: #ffffff !important;
            span.bg{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                cursor: pointer;
                z-index: 1;
                opacity: 0;
                background: rgba(0,0,0,0.4);
            }
            img{
                width: 100%;
            }
            .course-link{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 3;
            }
            figcaption{
                height: 100%;
                width: 100%;
                padding: 10px;
                position: absolute;
                left: 0;
                bottom: 0;
                top: auto;
                right: auto;
                z-index: 2;
                .title{
                    font-size: 20px;
                    line-height: normal;
                    color: $color_blue !important;
                    margin-bottom: 0;
                    font-family: Ubuntu, serif;
                    font-weight: 500;
                }
                .subtitle{
                    font-size: 15px;
                    line-height: 20px;
                    color: $color_blue !important;
                    font-family: Ubuntu, serif;
                    font-weight: 500;
                }
                .gotofyndery{
                    display: none;
                    z-index: 2;
                    .fa{
                        font-size: 30px;
                    }
                }
            }
            &:hover {
                span.bg {
                    opacity: 1;
                    transition: opacity 0.35s ease-in-out;
                }
                .gotofyndery{
                    display: block;
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    top: calc(50% - 10px);
                    width: calc(100% - 20px);
                    -webkit-transition: opacity 0.35s ease-in-out;
                    -o-transition: opacity 0.35s ease-in-out;
                    transition: opacity 0.35s ease-in-out;
                }
            }
        }
    }
}

// Navbar
.navbar{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 15px;
    @media (min-width: 768px) {
        padding: 0 90px;
    }
    .navbar-nav{
        .nav-item{
            margin: 0 0 0 40px;
            @media (max-width: 991px) {
                border-bottom: 1px solid rgba(0,0,0,.1);
                margin: 0;
                padding-left: 40px;
                &:hover, &:focus{
                    background-color: rgba(0,0,0,.1);
                }
                &:last-child{
                    border-bottom: none;
                }
            }
        }
        .nav-link{
            font-size: 1rem;
            padding: 25px 0;
            color: $color_blue;
            font-weight: 500;
            &:hover{
                text-decoration: none;
            }
        }
    }
}

// Footer
footer{
    border-top: 1px solid #d7c7bb;
    background: #f6f7f8;
    flex-shrink: 0;
    .sub-footer {
        display: table;
        width: 100%;
        .inner{
            padding: 50px 1vw;
            @media (min-width: 1200px) {
                padding: 50px 3vw;
            }
        }
        p{
            font-size: 16px;
            margin: 0;
        }
        ul.social{
            font-size: 18px;
            padding: 0;
            margin: 0 0 -5px 0;
            line-height: 1;
            li{
                margin: 0 5px 5px 5px;
                display: inline-block;
                line-height: 26px;
            }
        }
    }
}

// Sonstiges
#scrollToTopBtn{
    position: fixed;
    display: none;
    z-index: 1001;
    right: 20px;
    bottom: 20px;
    transition: background 150ms ease-in-out;
    -webkit-backface-visibility: hidden;
    .btn{
        background: rgba(0,54,89, 0.8);
        color: #fff;
        padding: 0.5rem 0.75rem;
    }
}