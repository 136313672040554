/* ubuntu-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    src: url("../../assets/webfonts/ubuntu-v20-latin-300.woff2") format('woff2');
}
/* ubuntu-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/webfonts/ubuntu-v20-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* ubuntu-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: url('../../assets/webfonts/ubuntu-v20-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
    font-family: 'SegoePrint';
    src: url('../../assets/webfonts/SegoePrint.ttf') format('truetype');
}
@font-face {
    font-family: 'CalibriBold';
    src: url('../../assets/webfonts/CalibriBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Font Awesome 6 Brands';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("../../assets/webfonts/fa-brands-400.woff2") format("woff2"),
    url("../../assets/webfonts/fa-brands-400.ttf") format("truetype"); }

@font-face {
    font-family: 'Font Awesome 6 Free';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("../../assets/webfonts/fa-regular-400.woff2") format("woff2"),
    url("../../assets/webfonts/fa-regular-400.ttf") format("truetype"); }

@font-face {
    font-family: 'Font Awesome 6 Free';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("../../assets/webfonts/fa-solid-900.woff2") format("woff2"),
    url("../../assets/webfonts/fa-solid-900.ttf") format("truetype"); }

